<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card>
      <b-card-title>
        <div class="p-2 d-flex justify-content-between">
          <span>ระบบแลกของรางวัล</span>
          <CreateModal @submit="upsert" />
        </div>
      </b-card-title>

      <b-row>
        <b-col sm="6" md="2" v-for="item in rewards">
          <b-card :img-src="item.img" img-alt="Image" img-top class="border">
            <div class="py-2">
              <h5>{{ item.type === 'MONEY' ? 'แลกเงินสด' : 'แลกเพชร' }} {{ item.value }}</h5>
              <p>จำนวนที่ใช้แลก: {{ item.amount }} {{ item.type === 'MONEY' ? 'เพชร' : 'บาท' }}</p>
              <p>Currency: {{ item.currency }}</p>
            </div>

            <div class="d-flex flex-row">
              <EditModal :data="item" @submit="upsert"/>
              
              <b-button size="sm" variant="danger" class="ml-1" @click="removeItem(item._id)">
                ลบ
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,

} from 'bootstrap-vue'
import CreateModal from './create.vue'
import EditModal from './edit.vue'

export default {
  components: {
    BRow, BCol, BCardTitle,
    BIcon,
    BDropdownItem,
    BDropdown,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    CreateModal,
    EditModal,
  },
  // props: ['items'],
  // emits: [],
  // filters: {
  //   convertDate(date) {
  //     return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
  //   },
  // },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'code', label: 'Code' },
      { key: 'type', label: 'ประเภท' },
      // { key: 'currency', label: 'สกุลเงิน' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'turnover', label: 'Turnover' },
      { key: 'start', label: 'เริ่ม' },
      { key: 'end', label: 'หมดอายุ' },
      { key: 'amount', label: 'จำนวนที่เหลือ' },
      { key: 'by_admin', label: 'สร้างโดย' },
      { key: 'actions', label: '#' },
    ],
    data: null,
    currentPage: 1,
    rewards: [],
    couponTypes: [],
    selectedItem: null
  }),
  mounted() {
    this.getItems()
  },
  methods: {
    async upsert(form) {
      this.isLoading = true;

      const fd = new FormData;

      fd.append('id', form.id ? form.id : '');
      fd.append('type', form.type);
      fd.append('value', form.value);
      fd.append('amount', form.amount);
      fd.append('limit', form.limit);
      fd.append('turnover', form.turnover);
      fd.append('currency', form.currency);
      fd.append('image', form.file);

      try {
        const { data } = await this.$http.post('/system/rewards', fd)

        this.getItems()
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async getItems() {
      // console.log(page, code)
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/system/rewards')

        this.rewards = data
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async removeItem(id) {
      // console.log(page, code)
      this.isLoading = true
      try {
        const { data } = await this.$http.delete(`/system/rewards/${id}`)

        this.getItems();
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    }
  },
}
</script>
