<template>
  <div>
    <b-button  v-b-modal="`editRewardModal${form.id}`" size="sm" variant="info">
      แก้ไข
    </b-button>
    <b-modal :id="`editRewardModal${form.id}`" v-model="modal" size="lg" title="สร้าง">

      <b-form>
        <div class="row">
          <div class="col-2">
            <b-form-group label="ประเภท" label-for="type_id">
              <b-form-select v-model="form.type" :options="couponTypes" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="มูลค่า" label-for="value">
              <b-form-input v-model="form.value" type="number" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group :label="`จำนวน${form.type === 'MONEY' ? 'เพชร' : 'เงิน'}ที่ใช้แลก`" label-for="amount">
              <b-form-input v-model="form.amount" type="number" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="Turnover(เท่า)" label-for="turnover">
              <b-form-input v-model="form.turnover" type="number" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="จำนวนรางวัล" label-for="limit">
              <b-form-input v-model="form.limit" type="number" />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="Currency" label-for="currency">
              <b-form-select v-model="form.currency" :options="currencyOptions" />
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group label="เลือกไฟล์" label-for="image_link">
              <b-form-file v-model="form.file" placeholder="เลือกไฟล์รูปภาพ..." accept="image/*"></b-form-file>
            </b-form-group>
          </div>
        </div>
      </b-form>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          สร้าง
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormFile, BForm, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BForm,
    BAlert,
    BSpinner,
    BFormCheckboxGroup,
    BFormCheckbox,
    BListGroupItem,
    BListGroup,
    BBadge,
    BOverlay,
    BIconController,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCardBody,
    flatPickr,
    BFormFile
  },
  directives: {
    Ripple,
  },
  props: ['data'],
  emits: ['submit'],
  data: () => ({
    modal: false,
    couponTypes: ['MONEY', 'DIAMOND'],
    currencyOptions: ['THB', 'KRW'],
    form: {
      type: 'MONEY',
      value: '',
      amount: '',
      turnover: 1,
      limit: '',
      currency: 'THB',
      file: null,
    },
  }),
  mounted() {
    this.form.id = this.$props.data._id.toString()
    this.form.type = this.$props.data.type
    this.form.amount = this.$props.data.amount
    this.form.value = this.$props.data.value
    this.form.limit = this.$props.data.limit
    this.form.turnover = this.$props.data.turnover
    this.form.currency = this.$props.data.currency
  },
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
    },
    cancel() {
      this.modal = false

      this.form = {
        type: 'MONEY',
        value: '',
        amount: '',
        limit: '',
        turnover: 1,
        currency: 'THB',
        file: null,
      }
    },
  },
}
</script>
